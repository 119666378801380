import { ProgressBar, Spinner } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { MODE } from "utils/Base";
import styles from "./TotalSpend.module.scss";
import Link from "next/link";
import format from "date-fns/format";

function TotalSpend({ data, loader, plan, pending }) {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    if (!plan) return;
    let fromDate = plan.expiredDate.$date.$numberLong;
    console.log(fromDate)
    setFromDate(format(new Date(Number(fromDate)), "MMM dd, yyyy"));
    let toDate = format(
      new Date(Number(plan.subscribedDate.$date.$numberLong)),
      "MMM dd, yyyy"
    );
    setToDate(toDate.toLocaleString("en-us", { month: "short" }));
  }, [plan]);

  return (
    <div className={styles.totalSpend}>
      <div className={styles.head}>
        <div className={styles.left}>
          <h2>Subscription plan details</h2>
          <h3>
            {/* ${data && <>{data.toFixed(1)}</>} */}
            {plan && (
              <small>
                {toDate} - {fromDate}
              </small>
            )}
          </h3>
        </div>
        {plan && (
          <div className={styles.right}>
            <button>{plan.planDetails.planName}</button>
            {plan.planDetails.planName !== "Enterprice Plan" && (
              <Link href={"/pricing"}>
                <h5>Upgrade</h5>
              </Link>
            )}
          </div>
        )}
      </div>
      {!plan || loader ? (
        <div className={styles.cardLoader}>
          <Spinner size="large" />
        </div>
      ) : (
        <>
          <hr />

          <div className={styles.orderBox}>
            <div className={styles.top}>
              <h3>Orders in queue</h3>
              <h4>
                {plan.monthlyOrdersLimit - plan.monthlyRemainingOrders}{" "}
                <small>/ {plan.monthlyOrdersLimit}</small>
              </h4>
            </div>
            <ProgressBar
              progress={
                ((plan.monthlyOrdersLimit - plan.monthlyRemainingOrders) /
                  plan.monthlyOrdersLimit) *
                100
              }
              size="small"
              color={
                ((plan.monthlyOrdersLimit - plan.monthlyRemainingOrders) /
                  plan.monthlyOrdersLimit) *
                  100 >
                90
                  ? "critical"
                  : "primary"
              }
            />
            {pending !== 0 && (
              <>
                <h5>{pending} orders pending for review request!</h5>
              </>
            )}
            {pending !== 0 && plan.planDetails.planName == "Free Plan" && (
              <hr />
            )}
            {/* <Link href={"/pricing"}>
              <h6 style={{ marginTop: "15px" }}>See billing details</h6>
            </Link> */}
            {plan.planDetails.planName == "Free Plan" && (
              <>
                <h2>Free review requests for up to 50 orders.</h2>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default TotalSpend;
