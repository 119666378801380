import { Page, Spinner } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import EnableModal from "../OnBoarding/EnableModal/EnableModal";
import styles from "./dashboard.module.scss";
import PendingSteps from "./PendingSteps/PendingSteps";
import TotalRating from "./TotalRating/TotalRating";
import TotalSpend from "./TotalSpend/TotalSpend";
import Cookies from "universal-cookie";
import main_apis from "api/main";
import Loader from "../Loader";
import { merchantToken } from "api/base";
import { MODE } from "utils/Base";
import Link from "next/link";
import SetupModal from "./SetupModal/SetupModal";
import toast from "react-hot-toast";
import format from "date-fns/format";
import Three60 from "./360";
import VerifyModal from "./360/VerifyModal";

const FilterBox = ({ activeDuration, setActiveDuration, getDashData }) => {
  return (
    <div className={styles.filterBox}>
      <h5>Show data for</h5>
      <ul>
        <li
          onClick={() => getDashData(1)}
          className={activeDuration == "1" ? styles.active : null}
        >
          Today
        </li>
        <li
          onClick={() => getDashData(7)}
          className={activeDuration == "7" ? styles.active : null}
        >
          7 days
        </li>
        <li
          onClick={() => getDashData(30)}
          className={activeDuration == "30" ? styles.active : null}
        >
          30 days
        </li>
        <li
          onClick={() => getDashData(90)}
          className={activeDuration == "90" ? styles.active : null}
        >
          90 days
        </li>
      </ul>
    </div>
  );
};

const Dashboard = ({
  setTour,
  onBoarding,
  initSetupStore,
  setInitSetupStore,
  data,
}) => {
  const cookies = new Cookies();
  const [dashData, setDashData] = useState(null);
  const [activeDuration, setActiveDuration] = useState(30);
  const [openSetup, setOpenSetup] = useState(false);
  const [setupType, setSetupType] = useState("");
  const [setupTitle, setSetupTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const [activePlan, setActivePlan] = useState(null);
  const [enableLoader, setEnableLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    if (!data) return;

    setDashData(data);
    setLoader(false);
  }, [data]);

  useEffect(() => {
    // setLoader(true);
    // main_apis
    //   .get_active_plan({
    //     token: cookies.get("merchantToken"),
    //     SHOPID: cookies.get("shopId"),
    //   })
    //   .then((res) => {
    //     if (res.data.Status == 200) {
    //       // setLoader(false);
    //     }
    //   });
  }, []);

  const checkStepStatus = () => {
    var perc = 0;
    if (dashData && dashData.stepsComplete.reviewStatus == true) {
      perc += 25;
    }

    if (dashData && dashData.stepsComplete.waitingForFirstReview !== 0) {
      perc += 25;
    }
    if (dashData && dashData.stepsComplete.moderationForFirstReview !== 0) {
      perc += 25;
    }
    if (dashData && dashData.stepsComplete.reviewWidgetInstallation == true) {
      perc += 25;
    }

    return perc == 100 ? true : false;
  };

  const getCountPercentage = (type) => {
    let read = dashData.read;
    let unread = dashData.deliverd;
    let undelivered = dashData.undeliverd;
    let total_count = parseInt(read) + parseInt(unread) + parseInt(undelivered);

    switch (type) {
      case "read":
        return ((read / total_count) * 100).toFixed(1);
      case "unread":
        return ((unread / total_count) * 100).toFixed(1);
      case "undelivered":
        return ((undelivered / total_count) * 100).toFixed(1);

      default:
        break;
    }
  };

  const getDashData = useCallback(
    (dur, load = true) => {
      setActiveDuration(dur);
      load && setLoader(true);
      main_apis
        .dashboard_data({
          days: dur,
          token: cookies.get("merchantToken"),
          SHOPID: cookies.get("shopId"),
        })
        .then((res) => {
          if (res.data.Status == 200) {
            setDashData(res.data.data);
            setActivePlan(res.data.data.activePlan);
            setLoader(false);
          }
        });
    },
    [activeDuration]
  );

  const getRecommendedPlan = (order, active_limit) => {
    switch (parseInt(active_limit)) {
      case 50:
        if (parseInt(order + active_limit) > 50) {
          return "Growth Plan";
        }
        break;
      case 500:
        if (parseInt(order + active_limit) > 500) {
          return "Premium";
        }
        break;

      case 3000:
        if (parseInt(order + active_limit) > 3000) {
          return "Business";
        }
        break;

      case 6000:
        if (parseInt(order + active_limit) > 6000) {
          return "Enterprise";
        }
        break;

      default:
        return "Higher Plan";
    }
  };

  // const [initSetupStore, setInitSetupStore] = useState(
  //   onBoarding ? false : true
  // );

  const manageReviewStatus = (status = true) => {
    setEnableLoader(true);
    main_apis
      .manage_review_status(
        status,
        cookies.get("merchantToken"),
        cookies.get("shopId")
      )
      .then((res) => {
        if (res.data.Status == 200) {
          toast.success(res.data.Message);
          setEnableLoader(false);
          getDashData(30, false);
        }
      });
  };

  return (
    <>
      {initSetupStore && (
        <EnableModal
          open={initSetupStore}
          setOpen={setInitSetupStore}
          datas={dashData}
          getDashData={getDashData}
          activePlan={dashData && dashData.activePlan}
        />
      )}

      <Page fullWidth>
        {openSetup && setupType && (
          <SetupModal
            open={openSetup}
            setOpen={setOpenSetup}
            type={setupType}
            title={setupTitle}
            datas={dashData}
            getDashData={getDashData}
          />
        )}
        <FilterBox
          activeDuration={activeDuration}
          setActiveDuration={setActiveDuration}
          getDashData={getDashData}
        />
        <div className={styles.dashboard} id="dashboard">
          <div className={styles.main}>
            {dashData && dashData.reviewStatus == false && (
              <div className={styles.welcomeCard}>
                <h2>Welcome to Reviewbit 🎉</h2>

                <p>
                  Ask reviews directly from social channels and get quick review
                  on your product
                </p>
                <h3 onClick={() => setTour(true)}>See how Reviewbit works</h3>
              </div>
            )}
            {dashData && dashData.orderBackupStatus == "Not Requested" && (
              <div className={styles.sendPastOrders}>
                <h5>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 8V12M12 16H12.01M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                      stroke="#3F3F46"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Collect more reviews?
                </h5>
                <h6
                  onClick={() => {
                    setOpenSetup(true),
                      setSetupType("PASTORDERS"),
                      setSetupTitle("Enable past orders");
                  }}
                >
                  Send for past orders
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.29279 14.707C7.10532 14.5195 7 14.2651 7 14C7 13.7348 7.10532 13.4805 7.29279 13.293L10.5858 9.99998L7.29279 6.70698C7.11063 6.51838 7.00983 6.26578 7.01211 6.00358C7.01439 5.74138 7.11956 5.49057 7.30497 5.30516C7.49038 5.11975 7.74119 5.01458 8.00339 5.01231C8.26558 5.01003 8.51818 5.11082 8.70679 5.29298L12.7068 9.29298C12.8943 9.48051 12.9996 9.73482 12.9996 9.99998C12.9996 10.2651 12.8943 10.5195 12.7068 10.707L8.70679 14.707C8.51926 14.8945 8.26495 14.9998 7.99979 14.9998C7.73462 14.9998 7.48031 14.8945 7.29279 14.707Z"
                      fill="#347C84"
                    />
                  </svg>
                </h6>
              </div>
            )}
            {dashData && dashData?.activePlan?.monthlyRemainingOrders == 0 && (
              <div className={styles.limitCard}>
                <h2 className={styles.title}>
                  You have exceeded the usage limit for your current plan
                </h2>
                <p className={styles.desc}>
                  Upgrade to{" "}
                  {dashData &&
                    getRecommendedPlan(
                      dashData.ordersWaitingToSend,
                      dashData.activePlan.monthlyOrdersLimit
                    )}{" "}
                  to access the reviews collected beyond your current plan
                  limit.
                </p>
                <Link href={"/pricing"}>
                  <button className={styles.btnMain}>
                    Upgrade Now
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.1667 6.66669L17.5 10M17.5 10L14.1667 13.3334M17.5 10H2.5"
                        stroke="#3F3F46"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </Link>
              </div>
            )}
            {dashData && dashData.reviewStatus == false && (
              <div className={styles.enableCard}>
                <h2>Review requests are paused</h2>
                <p>
                  Enable your review request to start collecting reviews via
                  Whatsapp
                </p>
                {enableLoader ? (
                  <button className={styles.btnLdr}>
                    <Spinner size="small" />
                  </button>
                ) : (
                  <button
                    className={styles.btnCard}
                    onClick={() => manageReviewStatus(true)}
                  >
                    Enable now
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.1667 6.66669L17.5 10M17.5 10L14.1667 13.3334M17.5 10H2.5"
                        stroke="#3F3F46"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
            )}
            {dashData &&
              dashData.stepsComplete.reviewWidgetInstallation == false && (
                <div className={styles.installCard}>
                  <h2>Enable on-site widget</h2>
                  <p>
                    Publish reviews on your product page without using multiple
                    tools. Reviewbit collects reviews faster than email.
                  </p>
                  <Link href={"/review-widget/full-page"}>
                    <button className={styles.btnCard}>
                      Enable now
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.1667 6.66669L17.5 10M17.5 10L14.1667 13.3334M17.5 10H2.5"
                          stroke="#3F3F46"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </Link>
                </div>
              )}

            {/* {dashData &&
              dashData.reviewsWaitingToSend !== 0 &&
              dashData.activePlan.monthlyRemainingOrders == 0 && (
                <div className={styles.orderCard}>
                  <h3>
                    {dashData.reviewsWaitingToSend} reviews are waiting to be
                    sent
                  </h3>
                  <Link href={"/pricing"}>
                    <button>
                      Upgrade
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.29279 14.707C7.10532 14.5195 7 14.2652 7 14C7 13.7349 7.10532 13.4806 7.29279 13.293L10.5858 10L7.29279 6.70704C7.11063 6.51844 7.00983 6.26584 7.01211 6.00364C7.01439 5.74144 7.11956 5.49063 7.30497 5.30522C7.49038 5.11981 7.74119 5.01465 8.00339 5.01237C8.26558 5.01009 8.51818 5.11088 8.70679 5.29304L12.7068 9.29304C12.8943 9.48057 12.9996 9.73488 12.9996 10C12.9996 10.2652 12.8943 10.5195 12.7068 10.707L8.70679 14.707C8.51926 14.8945 8.26495 14.9998 7.99979 14.9998C7.73462 14.9998 7.48031 14.8945 7.29279 14.707Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </Link>
                </div>
              )} */}
            {/* {dashData &&
              dashData.reviewsWaitingToSend !== 0 &&
              dashData.activePlan.monthlyRemainingOrders == 0 && (
                <div className={styles.expiredOrder}>
                  <h5>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 8V12M12 16H12.01M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                        stroke="#3F3F46"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {dashData.reviewsWaitingToSend} orders are waiting to be
                    sent
                  </h5>
                  <h6
                    onClick={() => {
                      setOpenSetup(true),
                        setSetupType("RESENDDUE"),
                        setSetupTitle("Resend expired review requests");
                    }}
                  >
                    Take Action
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.29279 14.707C7.10532 14.5195 7 14.2652 7 14C7 13.7349 7.10532 13.4806 7.29279 13.293L10.5858 10L7.29279 6.70704C7.11063 6.51844 7.00983 6.26584 7.01211 6.00364C7.01439 5.74144 7.11956 5.49063 7.30497 5.30522C7.49038 5.11981 7.74119 5.01465 8.00339 5.01237C8.26558 5.01009 8.51818 5.11088 8.70679 5.29304L12.7068 9.29304C12.8943 9.48057 12.9996 9.73488 12.9996 10C12.9996 10.2652 12.8943 10.5195 12.7068 10.707L8.70679 14.707C8.51926 14.8945 8.26495 14.9998 7.99979 14.9998C7.73462 14.9998 7.48031 14.8945 7.29279 14.707Z"
                        fill="#347C84"
                      />
                    </svg>
                  </h6>
                </div>
              )} */}
            <div className={styles.sendingCounts}>
              {loader == true || !dashData ? (
                <div className={styles.cardLoader}>
                  <Spinner size="large" />
                </div>
              ) : (
                <>
                  <div className={styles.parent}>
                    <div className={styles.left}>
                      <h3>Review requests sent</h3>
                      <div className={styles.count}>
                        <h4>{dashData.reviewRequestSent}</h4>
                        <h5>
                          From{" "}
                          {format(new Date(dashData.dateFrom), "MMM dd, yyyy")}
                        </h5>
                      </div>
                    </div>
                    <div className={styles.right}>
                      <div className={styles.progress}>
                        {getCountPercentage("read") != 0 && (
                          <span
                            style={{ width: `${getCountPercentage("read")}%` }}
                            className={styles.ct1}
                          ></span>
                        )}
                        {getCountPercentage("unread") != 0 && (
                          <span
                            style={{
                              width: `${getCountPercentage("unread")}%`,
                            }}
                            className={styles.ct2}
                          ></span>
                        )}
                        {getCountPercentage("undelivered") != 0 && (
                          <span
                            style={{
                              width: `${getCountPercentage("undelivered")}%`,
                            }}
                            className={styles.ct3}
                          ></span>
                        )}
                      </div>
                      <div className={styles.counts}>
                        <div className={`${styles.countItem} ${styles.ctc1}`}>
                          <span></span>
                          <h5>Read</h5>
                          <h6>
                            {dashData && isNaN(getCountPercentage("read"))
                              ? 0
                              : getCountPercentage("read")}
                            %
                          </h6>
                        </div>
                        <div className={`${styles.countItem} ${styles.ctc2}`}>
                          <span></span>
                          <h5>Unread</h5>
                          <h6>
                            {dashData && isNaN(getCountPercentage("unread"))
                              ? 0
                              : getCountPercentage("unread")}
                            %
                          </h6>
                        </div>
                        <div className={`${styles.countItem} ${styles.ctc3}`}>
                          <span></span>
                          <h5>Undelivered</h5>
                          <h6>
                            {dashData &&
                            isNaN(getCountPercentage("undelivered"))
                              ? 0
                              : getCountPercentage("undelivered")}
                            %
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.bottom}>
                    <Link href={"/review-request"}>
                      <h6
                      // onClick={() => (window.location.href = "/review-request")}
                      >
                        View All
                      </h6>
                    </Link>
                  </div>
                </>
              )}
            </div>
            {dashData && dashData.reviewsWaitingToSend !== 0 && (
              <div className={styles.expiredOrder}>
                <div className={styles.actionItem}>
                  <h5>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 8V12M12 16H12.01M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                        stroke="#3F3F46"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    You have&nbsp;<b>{dashData.reviewsWaitingToSend}</b>
                    &nbsp;pending review requests
                  </h5>
                  {/* // <Link href={"/pricing"}>
                    //   <h6>
                    //     Upgrade to send
                    //     <svg
                    //       width="20"
                    //       height="20"
                    //       viewBox="0 0 20 20"
                    //       fill="none"
                    //       xmlns="http://www.w3.org/2000/svg"
                    //     >
                    //       <path
                    //         fill-rule="evenodd"
                    //         clip-rule="evenodd"
                    //         d="M7.29279 14.707C7.10532 14.5195 7 14.2652 7 14C7 13.7349 7.10532 13.4806 7.29279 13.293L10.5858 10L7.29279 6.70704C7.11063 6.51844 7.00983 6.26584 7.01211 6.00364C7.01439 5.74144 7.11956 5.49063 7.30497 5.30522C7.49038 5.11981 7.74119 5.01465 8.00339 5.01237C8.26558 5.01009 8.51818 5.11088 8.70679 5.29304L12.7068 9.29304C12.8943 9.48057 12.9996 9.73488 12.9996 10C12.9996 10.2652 12.8943 10.5195 12.7068 10.707L8.70679 14.707C8.51926 14.8945 8.26495 14.9998 7.99979 14.9998C7.73462 14.9998 7.48031 14.8945 7.29279 14.707Z"
                    //         fill="#347C84"
                    //       />
                    //     </svg>
                    //   </h6>
                    // </Link> */}
                  <h6
                    onClick={() => {
                      setOpenSetup(true),
                        setSetupType("RESENDDUE"),
                        setSetupTitle("Resend pending review requests");
                    }}
                  >
                    Take Action
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.29279 14.707C7.10532 14.5195 7 14.2652 7 14C7 13.7349 7.10532 13.4806 7.29279 13.293L10.5858 10L7.29279 6.70704C7.11063 6.51844 7.00983 6.26584 7.01211 6.00364C7.01439 5.74144 7.11956 5.49063 7.30497 5.30522C7.49038 5.11981 7.74119 5.01465 8.00339 5.01237C8.26558 5.01009 8.51818 5.11088 8.70679 5.29304L12.7068 9.29304C12.8943 9.48057 12.9996 9.73488 12.9996 10C12.9996 10.2652 12.8943 10.5195 12.7068 10.707L8.70679 14.707C8.51926 14.8945 8.26495 14.9998 7.99979 14.9998C7.73462 14.9998 7.48031 14.8945 7.29279 14.707Z"
                        fill="#347C84"
                      />
                    </svg>
                  </h6>
                </div>
              </div>
            )}
            {dashData && dashData.expiredReviews !== 0 && (
              <div className={styles.expiredOrder}>
                <div className={styles.actionItem}>
                  <h5>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 8V12M12 16H12.01M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                        stroke="#3F3F46"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {dashData.expiredReviews} review request(s) expired
                  </h5>
                  <h6
                    onClick={() => {
                      setOpenSetup(true),
                        setSetupType("RESENDORDERS"),
                        setSetupTitle("Resend expired review requests");
                    }}
                  >
                    Take Action
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.29279 14.707C7.10532 14.5195 7 14.2652 7 14C7 13.7349 7.10532 13.4806 7.29279 13.293L10.5858 10L7.29279 6.70704C7.11063 6.51844 7.00983 6.26584 7.01211 6.00364C7.01439 5.74144 7.11956 5.49063 7.30497 5.30522C7.49038 5.11981 7.74119 5.01465 8.00339 5.01237C8.26558 5.01009 8.51818 5.11088 8.70679 5.29304L12.7068 9.29304C12.8943 9.48057 12.9996 9.73488 12.9996 10C12.9996 10.2652 12.8943 10.5195 12.7068 10.707L8.70679 14.707C8.51926 14.8945 8.26495 14.9998 7.99979 14.9998C7.73462 14.9998 7.48031 14.8945 7.29279 14.707Z"
                        fill="#347C84"
                      />
                    </svg>
                  </h6>
                </div>
              </div>
            )}
            <div className={styles.countCards}>
              <div className={styles.countBox}>
                <h2>Total reviews collected</h2>
                {loader == true || !dashData ? (
                  <div className={styles.cardLoader}>
                    <Spinner size="large" />
                  </div>
                ) : (
                  <>
                    <div className={styles.count}>
                      <h1>{dashData.collectedReviews}</h1>
                      <small>
                        From{" "}
                        {format(new Date(dashData.dateFrom), "MMM dd, yyyy")}
                      </small>
                    </div>
                    <Link href={"/reviews"}>
                      <h3>See all past reviews</h3>
                    </Link>
                  </>
                )}
              </div>
              <div className={styles.countBox}>
                <h2>Review requests in queue</h2>
                {loader == true || !dashData ? (
                  <div className={styles.cardLoader}>
                    <Spinner size="large" />
                  </div>
                ) : (
                  <>
                    <div className={styles.count}>
                      <h1>{dashData.waitingQueue}</h1>
                    </div>
                    <Link href={"/review-request?status=queue"}>
                      <h3>Monitor Queue</h3>
                    </Link>
                  </>
                )}
              </div>

              <div className={styles.countBox}>
                <h2>Awaiting approval </h2>
                {loader == true || !dashData ? (
                  <div className={styles.cardLoader}>
                    <Spinner size="large" />
                  </div>
                ) : (
                  <>
                    <div className={styles.count}>
                      <h1>{dashData.waitingApproval}</h1>
                    </div>
                    <Link href={"/reviews?status=unapproved"}>
                      <h3>Manage pending reviews</h3>
                    </Link>
                  </>
                )}
              </div>
              <div className={styles.countBox}>
                <h2>Total spent</h2>
                {loader == true || !dashData ? (
                  <div className={styles.cardLoader}>
                    <Spinner size="large" />
                  </div>
                ) : (
                  <>
                    <div className={styles.count}>
                      <h1>${dashData.totalspend.toFixed(1)}</h1>
                      <small>
                        From{" "}
                        {format(new Date(dashData.dateFrom), "MMM dd, yyyy")}
                      </small>
                    </div>
                    <Link href={"/billing"}>
                      <h3>See billing</h3>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={styles.aside}>
            {dashData && checkStepStatus() == false && (
              <PendingSteps data={dashData.stepsComplete} />
            )}
            <TotalRating loader={loader} data={dashData && dashData.rating} />

            <Three60 activePlan={dashData && dashData.activePlan} />

            <TotalSpend
              pending={dashData && dashData.ordersWaitingToSend}
              loader={loader}
              data={dashData && dashData}
              plan={dashData && dashData.activePlan}
            />
          </div>
        </div>
      </Page>
    </>
  );
};

export default Dashboard;
