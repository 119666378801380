import React, { useCallback, useEffect, useState, useRef } from "react";
import styles from "./SetupModal.module.scss";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import main_apis from "api/main";
import settings_api from "api/settings";
import toast from "react-hot-toast";
import useSWR from "swr";
import { BASE_URL } from "utils/Base";
import axios from "axios";
import { merchantToken, shopId } from "api/base";
import { Spinner } from "@shopify/polaris";

const SetupModal = ({ open, setOpen, title, type, datas, getDashData }) => {
  const cookies = new Cookies();
  const [data, setData] = useState();
  const [message, setMessage] = useState();
  const [enableConvo, setConvo] = useState(false);
  const [pastOrders, setPastOrders] = useState(false);
  const [event, setEvent] = useState("delivery");
  const [eventDay, setEventDay] = useState(10);
  const [eventFallbackDay, setEventFallbackDay] = useState(14);
  const [reviewId, setReviewId] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [chat, setChat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [backupDays, setBackupDays] = useState(90);

  useEffect(() => {
    if (!datas) return;
    setConvo(datas.reviewStatus);
    setPastOrders(datas.orderBackupStatus !== "Not Requested" ? true : false);
  }, [datas]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      minWidth: "600px",
      transform: "translate(-50%, -50%)",
      maxWidth: "1000px",
      background: "rgba(0,0,0,0)",
      border: "0",
      overflow: "visible",
      zIndex: "800",
    },
  };

  const importPastOrders = () => {
    // setPastOrders(!pastOrders);
    setLoading(true);
    main_apis
      .import_past_orders(
        backupDays,
        cookies.get("merchantToken"),
        cookies.get("shopId")
      )
      .then((res) => {
        if (res.data.Status == "200") {
          toast.success(res.data.Message);
          setLoading(false);

          setOpen(false);
          getDashData(30, false);
        }
      });
  };

  const handleUpdateMessageEvent = ({
    a = event,
    b = eventDay,
    c = eventFallbackDay,
  }) => {
    setLoading(true);

    settings_api
      .updateScheduleTiming({
        messageEvent: a,
        timeGap: b,
        fallBackTiming: c,
      })
      .then((res) => {
        if (res.data.Status == "200") {
          // toastr.success(res.data.Message);
          //   toast.success(res.data.Message);
        setLoading(false);

        }
      });
  };

  const manageReviewStatus = (status) => {
    setConvo(status);
    setLoading(true);

    main_apis
      .manage_review_status(
        status,
        cookies.get("merchantToken"),
        cookies.get("shopId")
      )
      .then((res) => {
        if (res.data.Status == 200) {
          // toast.success(res.data.Message);
          // getDashData(30,false);
        setLoading(false);

        }
      });
  };

  const rescheduleExpiredReviews = () => {
    setLoading(true);

    main_apis.reschedule_expired_reviews( cookies.get("merchantToken"),
    cookies.get("shopId")).then((res) => {
      if (res.data.Status == 200) {
        toast.success(res.data.Message);
        getDashData(30, false);
        setLoading(false);

        setOpen(false);
      } else if (res.data.Status == 400) {
        toast.error(res.data.Message);
        setLoading(false);

        setOpen(false);
      }
    });
  };

  const rescheduleDueExpiredReviews = () => {
    setLoading(true);

    main_apis.reschedule_past_reviews(cookies.get("merchantToken"),
    cookies.get("shopId")).then((res) => {
      if (res.data.Status == 200) {
        toast.success(res.data.Message);
        getDashData(30, false);
        setLoading(false);

        setOpen(false);
      } else if (res.data.Status == 400) {
        toast.error(res.data.Message);
        setOpen(false);
        setLoading(false);

      }
    });
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => {
        setOpen(false), getDashData(30, false);
      }}
      contentLabel=""
      style={customStyles}
    >
      <div className={styles.setupModal}>
        <div className={styles.leftMain}>
          <h3>{title}</h3>
          <hr />
          {type == "ENABLEREVIEW" && (
            <div className={styles.settingsItem}>
              <div className={styles.left}>
                <h4>Enable review request</h4>
                <p>
                  {" "}
                  By enabling this we will send a review request to each new
                  order*
                </p>
              </div>
              <div className={styles.right}>
                <label className={styles.switch}>
                  <input
                    checked={enableConvo}
                    onChange={(e) => manageReviewStatus(e.target.checked)}
                    type="checkbox"
                  />
                  <span className={styles.slider}></span>
                </label>
              </div>
            </div>
          )}
          {/* <hr /> */}
          {type == "REVIEWEVENT" && (
            <div className={styles.requestSettings}>
              <h4>Send review request</h4>
              <div className={styles.right}>
                <select
                  value={eventDay}
                  onChange={(e) => {
                    setEventDay(e.target.value),
                      handleUpdateMessageEvent({ b: e.target.value });
                  }}
                  name=""
                  id=""
                >
                  <option value="15">15 days after</option>
                  <option value="10">10 days after</option>
                  <option value="5">5 days after</option>
                </select>
                <select
                  value={event}
                  onChange={(e) => {
                    setEvent(e.target.value),
                      handleUpdateMessageEvent({ a: e.target.value });
                  }}
                >
                  <option value="fullfillment">fullfillment</option>
                  <option value="delivery">Delivery</option>
                  <option value="purchase">Purchase</option>
                </select>
              </div>
            </div>
          )}
          {/* <hr /> */}
          {type == "PASTORDERS" && (
            <>
              <div className={styles.settingsItem}>
                <div className={styles.left}>
                  <h4>Send review requests for past orders?</h4>
                  <p>
                    We will send review request for past{" "}
                    <select
                      value={backupDays}
                      onChange={(e) => setBackupDays(e.target.value)}
                    >
                      <option value={15}>15</option>
                      <option value={30}>30</option>
                      <option value={90}>90</option>
                    </select>{" "}
                    days orders
                  </p>
                </div>
                {/* <div className={styles.right}>
                  <label className={styles.switch}>
                    <input
                      checked={pastOrders}
                      onChange={() => importPastOrders()}
                      type="checkbox"
                    />
                    <span className={styles.slider}></span>
                  </label>
                </div> */}
              </div>

              {/* <p className={styles.desc}>50 orders are FREE for each month.</p> */}
            </>
          )}
          {type == "RESENDORDERS" && (
            <div className={styles.settingsItem}>
              <div className={styles.left}>
                <h4>
                  Are you sure you want to reschedule the expired review request
                  for next week?
                </h4>
              </div>
            </div>
          )}
          {type == "RESENDDUE" && (
            <div className={styles.settingsItem}>
              <div className={styles.left}>
                <h4>
                  Are you sure you want to reschedule the expired review request
                  for next week?
                </h4>
              </div>
            </div>
          )}

          <hr />
          <div className={styles.actions}>
            {type !== "RESENDORDERS" ||
              (type == "RESENDDUE" && (
                <button
                  onClick={() => {
                    setOpen(false), getDashData(30, false);
                  }}
                  className={styles.saveRequest}
                >
                  Close
                </button>
              ))}
            {type == "RESENDDUE" && (
              <>
                <button
                  onClick={() => {
                    setOpen(false), getDashData(30, false);
                  }}
                  className={styles.cancel}
                >
                  No, Cancel
                </button>
                {loading ?  <button className={styles.btnLoader}><Spinner  accessibilityLabel="Small spinner example" size="small" /></button> : (


                <button
                  onClick={() => rescheduleExpiredReviews()}
                  className={styles.agree}
                >
                  Yes, Confirm
                </button>
                )}
              </>
            )}

            {type == "PASTORDERS" && (
              <>
                {loading ?  <button className={styles.btnLoader}><Spinner  accessibilityLabel="Small spinner example" size="small" /></button> : (

                <button
                  onClick={() => importPastOrders()}
                  className={styles.agree}
                >
                  Confirm
                </button>)}
              </>
            )}
            {type == "RESENDORDERS" && (
              <>
                <button
                  onClick={() => {
                    setOpen(false), getDashData(30, false);
                  }}
                  className={styles.cancel}
                >
                  No, Cancel
                </button>
                {loading ?  <button className={styles.btnLoader}><Spinner  accessibilityLabel="Small spinner example" size="small" /></button> : (

                <button
                  onClick={() => rescheduleDueExpiredReviews()}
                  className={styles.agree}
                >
                  Yes, Confirm
                </button>)}
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SetupModal;
