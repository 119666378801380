import React, { useState, useEffect, useContext } from "react";
import StepComponent from "./StepComponent";
import { ProductCard } from "./StepThree";
import styles from "../OnBoarding.module.scss";
import useSWR from "swr";
import axios from "axios";
import { BASE_URL } from "utils/Base";
import { GlobalContext } from "context/GlobalContext";
import Cookies from "universal-cookie";

export const QuickReplies = ({ active = null }) => {
  const [replies, setReplies] = useState([
    {
      text: "Bad",
    },
    {
      text: "Good",
    },
    {
      text: "Excellent",
    },
    // {
    //   text: "Awesome",
    // },
  ]);

  return (
    <div className={styles.quickReplies}>
      {replies.map((i) => (
        <div
          className={`${styles.replyItem} ${active == i.text && styles.active}`}
        >
          {active == i.text && (
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM13.707 8.707C13.8892 8.5184 13.99 8.2658 13.9877 8.0036C13.9854 7.7414 13.8802 7.49059 13.6948 7.30518C13.5094 7.11977 13.2586 7.0146 12.9964 7.01233C12.7342 7.01005 12.4816 7.11084 12.293 7.293L9 10.586L7.707 9.293C7.5184 9.11084 7.2658 9.01005 7.0036 9.01233C6.7414 9.0146 6.49059 9.11977 6.30518 9.30518C6.11977 9.49059 6.0146 9.7414 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.707L13.707 8.707Z"
                  fill="#458FFF"
                />
              </svg>
            </div>
          )}
          {i.text}
        </div>
      ))}
    </div>
  );
};

const StepFour = ({ setSteps, product, getReviewReply, setTour }) => {
  const { isauth, merchant_token, shop_token } = useContext(GlobalContext);
  const cookies = new Cookies();

  const fetcher = (url) =>
    axios.get(url).then((res) => getReviewReply(res.data.data));

  const { data, error } = useSWR(
    BASE_URL +
      `/onboard/get-review-replay?shopId=${cookies.get(
        "shopId"
      )}&merchantToken=${cookies.get("merchantToken")}&phoneNumber=${
        product.phoneNumber
      }&reviewId=${product.reviewRequestId}`,
    fetcher,
    { refreshInterval: 200 }
  );

  // useEffect(() => {
  //   setTimeout(() => {
  //     // setSteps(4);
  //     setTour(false);
  //   }, 20000);
  // }, []);

  return (
    <StepComponent
      progress={80}
      bottom={
        <div className={styles.resend}>
          <h5>
            Didn’t receive message?{" "}
            <small onClick={() => setSteps(2)}>Retry</small>
          </h5>
        </div>
      }
    >
      <div className={styles.stepFour}>
        <div className={styles.top}>
          <div className={styles.image}>
            <img src="/images/whatsappNot.png" alt="" />
          </div>
          <div className={styles.content}>
            <h5>Waiting for your reply</h5>
            <h6>Review request is sent to {product.phoneNumber}</h6>
          </div>
        </div>
        <ProductCard product={product} />
        <QuickReplies />
      </div>
    </StepComponent>
  );
};

export default StepFour;
