import React, { useState, useEffect } from 'react'
import ReactStars from "react-rating-stars-component";
import Style from './ratingstyle.module.scss'

const RatingButton = ({ rating, total, size = 15, activeColor = "#ffd700" }) => {
    const [key, setKey] = useState(Date.now())

    useEffect(() => {
        setKey(Date.now())
    }, [rating, total, size, activeColor])


    return (
        <div
        //  style={{padding:"5px 10px 5px 10px"}}
        >

       
            <ReactStars
                key={key}
                count={total}
                value={rating}
                size={size}
                edit={false}
                classNames={Style.rating}
                isHalf={true}
                activeColor={activeColor}

            />
        </div>
    )
}
export default RatingButton
