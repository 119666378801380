import React from "react";
import styles from "../OnBoarding.module.scss";

const StepComponent = ({
  progress = 0,
  progressMin = 0,
  bottom = null,
  children,
  top = false,
}) => {
  return (
    <div className={styles.onBoardingStep}>
      {top && (
        <div className={styles.top}>🎉 Yeyy! That was a quick review! </div>
      )}
      {progress > 0 && (
        <div className={styles.stepProgress}>
          <h5>See how Reviewbit works in 5 minutes</h5>
          <div className={styles.progressBar}>
            <span
              style={{ maxWidth: `${progress}%`, minWidth: `${progressMin}%` }}
            ></span>
          </div>
        </div>
      )}
      <div className={styles.stepBox}>{children}</div>
      {bottom && <div className={styles.stepBottom}>{bottom}</div>}
    </div>
  );
};

export default StepComponent;
