import React, { useEffect } from "react";
import styles from "../OnBoarding.module.scss";
import StepComponent from "./StepComponent";

export const ProductCard = ({ product }) => {
  return (
    <div className={styles.productCard}>
      <div className={styles.image}>
        <img src={product && product.productImage} alt="" />
      </div>
      <div className={styles.content}>
        <h4>{product && product.productName}</h4>
        {/* {product && product.text && <p> {product.text}</p>} */}
      </div>
    </div>
  );
};

const StepThree = ({ setSteps, product }) => {
  useEffect(() =>
    setTimeout(() => {
      setSteps(4);
    }, 3000)
  );
  return (
    <StepComponent progress={50} progressMin={50}>
      <div className={styles.stepThree}>
        <h5>Sending review request</h5>
        <h6>Request will be send to {product.phoneNumber}</h6>
        <ProductCard product={product} />
      </div>
    </StepComponent>
  );
};

export default StepThree;
