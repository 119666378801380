import Link from "next/link";
import React from "react";
import StepComponent from "./StepComponent";
import styles from "../OnBoarding.module.scss";

export const StepBottom = ({
  setTour,
  setOnboarding,
  setInitSetupStore,
  manageTour,
}) => {
  return (
    <div className={styles.skip}>
      {/* <Link href="/"> */}
      <h6
        onClick={() => {
          setInitSetupStore(true);
          setTour(false);
          setOnboarding(2);
          manageTour(2);
        }}
      >
        Skip tour and setup your store
      </h6>
      {/* </Link> */}
    </div>
  );
};

const StepOne = ({
  setSteps,
  setTour,
  setOnboarding,
  setInitSetupStore,
  manageTour,
}) => {
  return (
    <StepComponent
      progress={0}
      progressMin={0}
      bottom={
        <StepBottom
          setTour={setTour}
          setOnboarding={setOnboarding}
          setInitSetupStore={setInitSetupStore}
          manageTour={manageTour}
        />
      }
    >
      <div className={styles.stepOne}>
        <div className={styles.logo}>
          <img src="/images/rlogo.png" alt="" />
        </div>
        <h5>Welcome to ReviewBit</h5>
        <p>
          Reviewbit helps you collect reviews directly from your customers
          social apps{" "}
        </p>
        <button onClick={() => setSteps(2)} className={styles.nextBtn}>
          Get started
        </button>
      </div>
    </StepComponent>
  );
};

export default StepOne;
