import Wrapper from "../components/Wrapper";
import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  FormLayout,
  ChoiceList,
  DatePicker,
  Banner,
  Card,
  Layout,
  Stack,
  DisplayText,
  TextStyle,
  ProgressBar,
  Icon,
  Button,
  ButtonGroup,
  TextContainer,
  Heading,
  Image,
  Modal,
  Link,
} from "@shopify/polaris";
import {
  CalendarMajor,
  StarFilledMinor,
  StarOutlineMinor,
  ExportMinor,
} from "@shopify/polaris-icons";
import RatingButton from "../components/RatingButtons";
import Loading from "../components/Loading";
// import gql from "graphql-tag";
import { useRouter } from "next/router";
import apis, {
  getReviewStatusCount,
  onboardingflowstage,
  importorders,
} from "../api";
import { api } from "api/base";
import useSWR from "swr";
import { startOfMonth, endOfMonth } from "date-fns";
import Cookies from "universal-cookie";
import OnBoardingFlow from "@/components/OnBoardingFlow";
import PastCustomer from "@/components/Dashboard/pastcustomer";
// import toastr from "../lib/toastr";
import toastr from "react-hot-toast";
import format from "date-fns/format";
import Styles from "@/components/Dashboard/pastcustomer.module.scss";
import cn from "classnames";
import OnBoarding from "@/components/OnBoarding/OnBoarding";
import { MODE } from "utils/Base";
import Dashboard from "@/components/Dashboard/Dashboard";
import main_apis from "api/main";
import Loader from "@/components/Loader";

const HomePage = () => {
  const cookies = new Cookies();

  const router = useRouter();
  const [showBanner, setShowBanner] = useState(true);
  const [isPremium, setisPremium] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [convoload, setconvoload] = useState(false);
  const [reviewStatus, setReviewStatus] = useState(false);

  const [showRatingBanner, setShowRatingBanner] = useState(true);
  const [duration, setDuration] = useState("month");
  var [fitertype, setFitertype] = useState("month");
  const [notificationCount, setNotificationCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [auth, setAuth] = useState(false);
  const [limitModal, setlimitModal] = useState(false);
  const [tour, setTour] = useState(false);
  const [initSetupStore, setInitSetupStore] = useState(false);

  const [onBoarding, setOnboarding] = useState(0);
  const [dashData, setDashData] = useState(null);

  useEffect(() => {
    let onBoardStatus = cookies.get("onBoardStatus");
    setOnboarding(onBoardStatus);
    // setInitSetupStore(
    //   onBoardStatus == "1" || onBoardStatus == "2" ? false : true
    // );
  }, []);

  function togglelimitModal() {
    setlimitModal(!limitModal);
  }
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(startOfMonth(new Date())),
    end: new Date(endOfMonth(new Date())),
  });
  const [filterDate, setFilterDate] = useState({
    start: new Date(startOfMonth(new Date())),
    end: new Date(endOfMonth(new Date())),
  });
  const [merchantToken, setMerchantToken] = useState(0);
  const [storeName, setStoreName] = useState("");
  const [orderBackupDays, setOrderBackupDays] = useState();
  const [days, setDays] = useState("0");
  const [draftStatus, setDraftStatus] = useState("synced");

  const [dataset, setdataset] = useState({
    shopDomain: "",
    shopname: "",
    rateUsStatus: false,
    reviewStatus: false,
    timegap: "",
    onboardingFlowStage: null,
    orderBackupStatus: null,
    orderBackupDays: null,
    shopId: "",
    requestsSent: 0,
    deliverd: 0,
    undeliverd: 0,
    read: 0,
    star1: 0,
    star2: 0,
    star3: 0,
    star4: 0,
    star5: 0,
    totalspend: 0,
    whatsapp: 0,
    walletAmount: 0,
    isLoading: 0,
    migratedShop: null,
  });
  //     var now = new Date();
  //  var time = now.getTime();
  //  var expireTime = time + 1000*36000;
  var date = new Date();
  let expired1 = date.setTime(date.getTime() + 1 * 60 * 1000);

  // useEffect(() => {
  //     if(!isPremium ){
  //         if( !cookies.get('upgradepopup') ){
  //             setTimeout(() => {
  //                 setlimitModal(true);
  //               },1 * 60 * 1000);
  //             // setlimitModal(true);
  //             cookies.set('upgradepopup', 'upgradepopup', { path: '/',
  //             expires:new Date(Date.now()+7*24*60*60*10000)});
  //             cookies.set('showoneday', 'showoneday', { path: '/',
  //             expires:new Date(Date.now()+24*60*60*10000)});
  //             // setlimitModal(true);

  //         }
  //         else if(cookies.get('upgradepopup')){
  //             setlimitModal(false);
  //             if(cookies.get('showoneday'))
  //             {
  //                 setTimeout(() => {
  //                     setlimitModal(true);
  //                   }, 1 * 60 * 1000);
  //                 // setlimitModal(true);
  //             }
  //         }
  //     }

  // }, [isPremium])
  // setTimeout(()=>{

  // },)

  const handleClick = (e) => {
    // console.dir(e.target.id)
    setDuration(e.target.id);
    // console.log("inside click=="+e.target.id)
    // e.persist();
    // this.setState({ dataset.isLoading: true })
    // console.log(e.currentTarget.id);
    // api.getDatalist(e.currentTarget.id).then(movies => {
    //     console.log(movies.data)
    //     this.setState({
    //         movies: movies.data.Status,
    //         dataset.requestsSent: movies.data.dataset.requestsSent,
    //         dataset.deliverd: movies.data.dataset.deliverd,
    //         dataset.undeliverd: movies.data.dataset.undeliverd,
    //         dataset.read: movies.data.dataset.read,
    //         dataset.star1: movies.data.dataset.star1,
    //         dataset.star2: movies.data.dataset.star2,
    //         dataset.star3: movies.data.dataset.star3,
    //         dataset.star4: movies.data.dataset.star4,
    //         dataset.star5: movies.data.dataset.star5,
    //         dataset.totalspend: movies.data.dataset.totalspend,
    //         dataset.whatsapp: movies.data.dataset.whatsapp,
    //         dataset.isLoading: false,
    //     })
    // })
  };

  const handleDurationChange = (value) => {
    fitertype = setFitertype(value);
    // console.log("inside click=="+value+fitertype)
    if (value === "custom") setShowPopup(true);
    else setDuration(value);
  };
  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const handleDateSave = () => {
    // console.log("Popup save button action")
    setDuration("custom");
    setFilterDate(selectedDates);
    setShowPopup(false);
  };
  const handleOnBoardingFlow = (step) => {
    onboardingflowstage(merchantToken, dataset.shopId, step).then(() => {
      // console.log("printed next")
    });
  };
  const handleImportorders = (orderBackupDays) => {
    if (orderBackupDays[0] !== 0) {
      importorders(merchantToken, dataset.shopId, orderBackupDays[0]).then(
        () => {
          toastr.success("Review request to past orders updated");
          // console.log("import updated")
        }
      );
    } else {
      toastr.success("Review request to past orders skipped");
    }
  };
  const toggleShowPopup = () => {
    if (!days) setDays(dataset.orderBackupDays);
    setShowPopup1(!showPopup1);
  };

  // componentDidMount = async () => {
  //     this.setState({ dataset.isLoading: true })

  //     await api.getAllMovies().then(movies => {
  //         this.setState({
  //             movies: movies.data.Status,
  //             dataset.requestsSent: movies.data.dataset.requestsSent,
  //             dataset.deliverd: movies.data.dataset.deliverd,
  //             dataset.undeliverd: movies.data.dataset.undeliverd,
  //             dataset.read: movies.data.dataset.read,
  //             dataset.star1: movies.data.dataset.star1,
  //             dataset.star2: movies.data.dataset.star2,
  //             dataset.star3: movies.data.dataset.star3,
  //             dataset.star4: movies.data.dataset.star4,
  //             dataset.star5: movies.data.dataset.star5,
  //             dataset.totalspend: movies.data.dataset.totalspend,
  //             dataset.whatsapp: movies.data.dataset.whatsapp,
  //             dataset.isLoading: false,
  //         })
  //     })
  // }
  // useEffect(() => {
  // var url_string = window.location.href;
  //     var url = new URL(url_string);
  //     var shop = url.searchParams.get("shop");

  // if(url.searchParams.get("shop")){
  //     cookies.set('merchantToken', shop, { path: '/' });
  //     setMerchantToken(cookies.get('merchantToken'));
  //         let autheticaed=false

  //         const { data, error } = useSWR(`https://dev.reviewbit.app/api/dashboarddata?merchantToken=${shop}&dateFilter=${duration}&startDate=${ format(filterDate?.start, "yyyy-MM-dd")}&endDate=${ format(filterDate?.end, "yyyy-MM-dd")}`)
  //         if (data&&auth==false) {

  // getReviewStatusCount(merchantToken,data.shopId).then((res)=>{
  //     // console.log(res.data.Status)
  //     setAuth(true)
  //     setNotificationCount(res.data?.count)
  // })
  //         }

  //         if (error) {
  //             router.push("/Login")
  //             return <Loading />
  //         }
  //         else if (!data) {
  //             // router.push("/404")
  //             // return null
  //             return <Loading />
  //         }
  //         else{
  //             if(data.Status==401){
  //                 router.push("/Login")
  //             }
  //         // console.log(data.Status)

  //             autheticaed=true

  //         // console.log(data.shopname)
  // cookies.set('shopName', data.shopname, { path: '/' });
  // cookies.set('shopId', data.shopId, { path: '/' });
  // cookies.set('shopDomain', data.shopId, { path: '/' });
  //         }
  //         setdataset(data)
  //         // console.log(merchantToken)
  //         // console.log("ComponentDidMount script")
  //     }else{
  //         router.push("/Login")
  //         setMerchantToken(cookies.get('merchantToken'));
  //     }
  // }, [])

  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var shop = url.searchParams.get("shop");
    if (auth) {
      setMerchantToken(cookies.get("merchantToken"));
      api
        .get("/verify-shop-auth", {
          params: {
            merchantToken: cookies.get("merchantToken"),
          },
        })
        .then((res) => {
          if (res.data.Status == 200) {
            cookies.set("shopId", res.data.data.shopId, { path: "/" });
            cookies.set("shopName", res.data.data.shopName, { path: "/" });
            cookies.set("shopDomain", res.data.data.myShopifyDomain, { path: "/" });
            main_apis
              .dashboard_data({
                days: 30,
                token: cookies.get("merchantToken"),
                SHOPID: cookies.get("shopId"),
              })
              .then((resd) => {
                if (resd.data.Status == 200) {
                  setDashData(resd.data.data);
                  console.log(resd.data.data, "1");
                  cookies.set(
                    "ordersWatitingToSend",
                    resd.data.data.ordersWaitingToSend
                  );
                }
              });
          }
        });
      // api
      //   .get("/dashboarddata", {
      //     params: {
      //       merchantToken: cookies.get("merchantToken"),
      //       dateFilter: duration,
      //       startDate: format(filterDate?.start, "yyyy-MM-dd"),
      //       endDate: format(filterDate?.end, "yyyy-MM-dd"),
      //     },
      //   })
      //   .then(
      //     (response) => {
      //       setdataset(response.data);
      //       cookies.set("shopId", response.data.shopId, { path: "/" });
      //       main_apis
      //         .dashboard_data({
      //           days: 30,
      //           token: cookies.get("merchantToken"),
      //           SHOPID: cookies.get("shopId"),
      //         })
      //         .then((resd) => {
      //           if (resd.data.Status == 200) {
      //             setDashData(resd.data.data);
      //             console.log(resd.data.data, "1");
      //           }
      //         });
      //     },
      //     (error) => {
      //       toastr.warning("Some error, please try again");
      //     }
      //   );
      console.log("first_case (auth == true)");
    } else if (url.searchParams.get("shop")) {
      cookies.set("merchantToken", shop, { path: "/" });
      setMerchantToken(cookies.get("merchantToken"));
      api
        .get("/verify-shop-auth", {
          params: {
            merchantToken: shop,
          },
        })
        .then((resd) => {
          if (resd.data.Status == 200) {
            cookies.set("shopId", resd.data.data.shopId, { path: "/" });
            cookies.set("shopName", resd.data.data.shopName, { path: "/" });
            cookies.set("shopDomain", resd.data.data.myShopifyDomain, { path: "/" });

            getReviewStatusCount(shop, resd.data.data.shopId).then((res) => {
              // console.log(res.data.Status)
              setAuth(true);
              setNotificationCount(res.data?.count);
              setisPremium(res.data?.subscriptionStatus);
              setReviewStatus(res.data.reviewStatus);
              setDraftStatus(res.data.draftStatus);
              setApprovedCount(res.data.ApprovedreviewsCount);
              setOnboarding(res.data.onBoarding);
              cookies.set("onBoardStatus", res.data.onBoarding, {
                path: "/",
              });
              cookies.set("reviewStatus", res.data.reviewStatus, {
                path: "/",
              });
              cookies.set("countryName", res.data.countryName, {
                path: "/",
              });
              cookies.set("backupStatus", res.data.backupDays, {
                path: "/",
              });
              main_apis
                .dashboard_data({
                  days: 30,
                  token: cookies.get("merchantToken"),
                  SHOPID: cookies.get("shopId"),
                })
                .then((response) => {
                  if (response.data.Status == 200) {
                    setDashData(response.data.data);
                    console.log(response);
                    cookies.set(
                      "ordersWatitingToSend",
                      response.data.data.ordersWaitingToSend
                    );
                  }
                });
            });
          }
        });
      // api
      //   .get("/dashboarddata", {
      //     params: {
      //       merchantToken: shop,
      //       dateFilter: duration,
      //       startDate: format(filterDate?.start, "yyyy-MM-dd"),
      //       endDate: format(filterDate?.end, "yyyy-MM-dd"),
      //     },
      //   })
      //   .then(
      //     (response) => {
      //       setdataset(response.data);
      //       cookies.set("shopName", response.data.shopname, { path: "/" });
      //       cookies.set("shopId", response.data.shopId, { path: "/" });
      //       cookies.set("shopDomain", response.data.shopId, { path: "/" });

      //       getReviewStatusCount(shop, response.data.shopId).then((res) => {
      //         // console.log(res.data.Status)
      //         setAuth(true);
      //         setNotificationCount(res.data?.count);
      //         setisPremium(res.data?.subscriptionStatus);
      //         setReviewStatus(res.data.reviewStatus);
      //         setDraftStatus(res.data.draftStatus);
      //         setApprovedCount(res.data.ApprovedreviewsCount);
      //         setOnboarding(res.data.onBoarding);
      //         cookies.set("onBoardStatus", res.data.onBoarding, {
      //           path: "/",
      //         });
      //         cookies.set("reviewStatus", res.data.reviewStatus, { path: "/" });
      //         cookies.set("countryName", res.data.countryName, { path: "/" });
      //         cookies.set("backupStatus", res.data.backupDays, { path: "/" });
      //         main_apis
      //           .dashboard_data({
      //             days: 30,
      //             token: cookies.get("merchantToken"),
      //             SHOPID: cookies.get("shopId"),
      //           })
      //           .then((resd) => {
      //             if (resd.data.Status == 200) {
      //               setDashData(resd.data.data);
      //               console.log(resd);
      //             }
      //           });
      //       });
      //       //setAuth(true);
      //     },
      //     (error) => {
      //       console.log(error);
      //       if (error.response.status == 401) {
      //         router.push("/Login");
      //       } else {
      //         router.push("/error");
      //       }
      //     }
      //   );
      console.log("2_case (url)");
    } else if (cookies.get("merchantToken")) {
      setMerchantToken(cookies.get("merchantToken"));
      api
        .get("/verify-shop-auth", {
          params: {
            merchantToken: cookies.get("merchantToken"),
          },
        })
        .then((resd) => {
          if (resd.data.Status == 200) {
            cookies.set("shopId", resd.data.data.shopId, { path: "/" });
            cookies.set("shopName", resd.data.data.shopName, { path: "/" });
            cookies.set("shopDomain", resd.data.data.myShopifyDomain, { path: "/" });

            getReviewStatusCount(
              cookies.get("merchantToken"),
              resd.data.data.shopId
            ).then((res) => {
              // console.log(res.data.Status)
              setAuth(true);
              setNotificationCount(res.data?.count);
              setisPremium(res.data?.subscriptionStatus);
              setReviewStatus(res.data?.reviewStatus);
              setDraftStatus(res.data?.draftStatus);
              setOnboarding(res.data?.onBoarding);

              cookies.set("onBoardStatus", res.data.onBoarding, {
                path: "/",
              });
              cookies.set("reviewStatus", res.data.reviewStatus, { path: "/" });
              cookies.set("countryName", res.data.countryName, { path: "/" });
              cookies.set("backupStatus", res.data.backupDays, { path: "/" });
              main_apis
                .dashboard_data({
                  days: 30,
                  token: cookies.get("merchantToken"),
                  SHOPID: cookies.get("shopId"),
                })
                .then((response) => {
                  if (response.data.Status == 200) {
                    setDashData(response.data.data);
                    cookies.set(
                      "ordersWatitingToSend",
                      response.data.data.ordersWaitingToSend
                    );
                  }
                });
            });
          }
        });
      (error) => {
        // console.log(error);
        if (error.response.status == 401) {
          router.push("/Login");
        } else {
          router.push("/error");
        }
      };

      // api
      //   .get("/dashboarddata", {
      //     params: {
      //       merchantToken: cookies.get("merchantToken"),
      //       dateFilter: duration,
      //       startDate: format(filterDate?.start, "yyyy-MM-dd"),
      //       endDate: format(filterDate?.end, "yyyy-MM-dd"),
      //     },
      //   })
      //   .then(
      //     (response) => {
      //       setdataset(response.data);
      //       cookies.set("shopName", response.data.shopname, { path: "/" });
      //       cookies.set("shopId", response.data.shopId, { path: "/" });
      //       cookies.set("shopDomain", response.data.shopId, { path: "/" });

      //       getReviewStatusCount(
      //         cookies.get("merchantToken"),
      //         response.data.shopId
      //       ).then((res) => {
      //         // console.log(res.data.Status)
      //         setAuth(true);
      //         setNotificationCount(res.data?.count);
      //         setisPremium(res.data?.subscriptionStatus);
      //         setReviewStatus(res.data?.reviewStatus);
      //         setDraftStatus(res.data?.draftStatus);
      //         setOnboarding(res.data?.onBoarding);

      //         cookies.set("onBoardStatus", res.data.onBoarding, {
      //           path: "/",
      //         });
      //         cookies.set("reviewStatus", res.data.reviewStatus, { path: "/" });
      //         cookies.set("countryName", res.data.countryName, { path: "/" });
      //         cookies.set("backupStatus", res.data.backupDays, { path: "/" });
      //         main_apis
      //           .dashboard_data({
      //             days: 30,
      //             token: cookies.get("merchantToken"),
      //             SHOPID: cookies.get("shopId"),
      //           })
      //           .then((resd) => {
      //             if (resd.data.Status == 200) {
      //               setDashData(resd.data.data);
      //             }
      //           });
      //       });
      //       //setAuth(true);
      //     },
      //     (error) => {
      //       // console.log(error);
      //       if (error.response.status == 401) {
      //         router.push("/Login");
      //       } else {
      //         router.push("/error");
      //       }
      //     }
      //   );
      console.log("first_case (token)");
    } else {
      router.push("/Login");
    }
    return () => {};
  }, []);

  const togglePopup = () => {
    setShowPopup(!showPopup);
    // cookies.remove('showoneday') ;
    // cookies.set('doitlater','doitlater',{ path: '/',
    // expires:new Date(Date.now()+1*24*60*60*10000)});
  };

  //const {dataset.requestsSent = 0,dataset.deliverd = 0,dataset.undeliverd = 0,dataset.read = 0,dataset.star1 = 0,dataset.star2 = 0,dataset.star3 = 0,dataset.star4 = 0,dataset.star5 = 0,dataset.totalspend = 0,dataset.whatsapp = 0,dataset.walletAmount = 0, dataset.isLoading = 0,dataset.migratedShop}=data

  const rated = false;

  // const { movies, dataset.requestsSent = 0, dataset.deliverd, dataset.undeliverd, dataset.read, dataset.star1, dataset.star2, dataset.star3, dataset.star4, dataset.star5, dataset.totalspend = 0, dataset.whatsapp = 0, dataset.isLoading, rated = false } = data1
  // //console.log('TCL: MoviesList -> render -> movies', movies)
  if (auth) {
    if (
      (onBoarding == "1" && tour == false) ||
      (onBoarding == "2" && tour == false)
    ) {
      return (
        <Wrapper
          authenticated={notificationCount}
          isPremium={isPremium}
          approvedCount={approvedCount}
          draftStatus={draftStatus}
          navigation={true}
        >
        
          <Dashboard
            onBoarding={onBoarding}
            setTour={setTour}
            initSetupStore={initSetupStore}
            setInitSetupStore={setInitSetupStore}
            data={dashData}
          />
        </Wrapper>
      );
    }
    return (
      <OnBoarding
        setTour={setTour}
        setOnboarding={setOnboarding}
        setInitSetupStore={setInitSetupStore}
      />
    );
  } else {
    return (
      <Wrapper>
        <div className="fullPageLoader">
          <Loader />
        </div>
      </Wrapper>
    );
  }
};

export default HomePage;
