import React, { useEffect, useState } from "react";
import { ConnectButton } from "360dialog-connect-button";
import axios from "axios";
import main_apis from "api/main";
import VerifyModal from "./VerifyModal";
import styles from "../dashboard.module.scss";
import { Button } from "react-scroll";
import Cookies from "universal-cookie";
import Link from "next/link";
import { PARTNER360TOKEN } from "utils/Base";
import ManualModal from "./ManualModal";

function Three60(activePlan) {
  const [openVerifyModal, setVerifyModal] = useState(false);
  const [openManualModal, setManualModal] = useState(false);

  const [hasChannel, setChannel] = useState(false);
  const [status, setStatus] = useState("not-started");
  const [isPremium, setIsPremium] = useState(false);
  const [step, setStep] = useState(0);
  const [channel, setChannelData] = useState(null);
  const cookies = new Cookies();

  const handleCallback = (callbackObject) => {
    /* The callback function returns the client ID as well as all channel IDs, for which you're enabled to fetch the API key via the Partner API */

    console.log("client ID: " + callbackObject.client);
    console.log("channel IDs: " + callbackObject.channels);
    console.log(callbackObject, "360 callback");
    localStorage.setItem("three60", JSON.stringify(callbackObject));
    setVerifyModal(true);

    getChannelData();

  };

  useEffect(() => {
    let channel_id = JSON.parse(localStorage.getItem("three60"));
    if (activePlan) {
      console.log(activePlan?.activePlan?.planDetails?.planName );
      if (activePlan?.activePlan?.planDetails?.planName == "Free Plan") {
        setIsPremium(false);
      } else {
        setIsPremium(true);
      }
    }
    if (channel_id) {
      setChannel(true);
      setStep(1);
    } else {
      setChannel(false);
      setStep(0);
    }
  }, [activePlan]);

  const getChannelData = () => {
    main_apis
      .getChannelData(cookies.get("shopId"), cookies.get("merchantToken"))
      .then((res) => {
        setChannelData(res?.data?.data);
        if (!res?.data?.data?.channelId) {
          setStep(0);
          setStatus("not-started");
        } else if (
          res?.data?.data?.channelId &&
          !res?.data?.data?.channelApiKey
        ) {
          let Three60 = {
            client: "",
            channels: [...res?.data?.data?.channelId],
          };
          localStorage.setItem("three60", JSON.stringify(Three60));

          setStep(1);
          setStatus("not-started");
        } else if (
          res?.data?.data?.channelId &&
          res?.data?.data?.channelApiKey
        ) {
          setStep(2);
          setStatus("pending");
        } else if (
          res?.data?.data?.channelWebHookStatus &&
          res?.data?.data?.channelId &&
          res?.data?.data?.channelApiKey
        ) {
          setStep(3);
          setStatus("success");
        }
      });
  };

  useEffect(() => {
    getChannelData();
  }, []);

  const card = (status) => {
    switch (status) {
      case "not-started":
        return (
          <div className={styles.steps}>
            {!isPremium && (
              <div className={styles.upgrade}>
                <Link href="/pricing">
                  <button>
                    Upgrade for custom number
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.29279 14.7064C7.10532 14.5189 7 14.2646 7 13.9994C7 13.7343 7.10532 13.48 7.29279 13.2924L10.5858 9.99943L7.29279 6.70643C7.11063 6.51783 7.00983 6.26523 7.01211 6.00303C7.01439 5.74083 7.11956 5.49002 7.30497 5.30461C7.49038 5.1192 7.74119 5.01403 8.00339 5.01176C8.26558 5.00948 8.51818 5.11027 8.70679 5.29243L12.7068 9.29243C12.8943 9.47996 12.9996 9.73427 12.9996 9.99943C12.9996 10.2646 12.8943 10.5189 12.7068 10.7064L8.70679 14.7064C8.51926 14.8939 8.26495 14.9992 7.99979 14.9992C7.73462 14.9992 7.48031 14.8939 7.29279 14.7064Z"
                        fill="#AEB4B9"
                      />
                    </svg>
                  </button>
                </Link>
              </div>
            )}
            <ul>
              <li>
                <h5>
                  {step == 1 ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18V18ZM13.707 8.707C13.8892 8.5184 13.99 8.2658 13.9877 8.0036C13.9854 7.7414 13.8802 7.49059 13.6948 7.30518C13.5094 7.11977 13.2586 7.0146 12.9964 7.01233C12.7342 7.01005 12.4816 7.11084 12.293 7.293L9 10.586L7.707 9.293C7.5184 9.11084 7.2658 9.01005 7.0036 9.01233C6.7414 9.0146 6.49059 9.11977 6.30518 9.30518C6.11977 9.49059 6.0146 9.7414 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.707L13.707 8.707V8.707Z"
                        fill="#007F5F"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM13.707 8.707C13.8892 8.5184 13.99 8.2658 13.9877 8.0036C13.9854 7.7414 13.8802 7.49059 13.6948 7.30518C13.5094 7.11977 13.2586 7.0146 12.9964 7.01233C12.7342 7.01005 12.4816 7.11084 12.293 7.293L9 10.586L7.707 9.293C7.5184 9.11084 7.2658 9.01005 7.0036 9.01233C6.7414 9.0146 6.49059 9.11977 6.30518 9.30518C6.11977 9.49059 6.0146 9.7414 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.707L13.707 8.707Z"
                        fill="#BABEC3"
                      />
                    </svg>
                  )}
                  Create a 360dialog account
                </h5>
                <div className={styles.cta}>
                  {isPremium ? (
                    step == 0 ? (
                      <ConnectButton
                        partnerId={PARTNER360TOKEN}
                        callback={handleCallback}
                        label="Create"
                      />
                    ) : (
                      <button className={styles.disabled}>Create</button>
                    )
                  ) : (
                    <button className={styles.disabled}>Create</button>
                  )}
                  {step == 1 ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.29279 14.7064C7.10532 14.5189 7 14.2646 7 13.9994C7 13.7343 7.10532 13.48 7.29279 13.2924L10.5858 9.99943L7.29279 6.70643C7.11063 6.51783 7.00983 6.26523 7.01211 6.00303C7.01439 5.74083 7.11956 5.49002 7.30497 5.30461C7.49038 5.1192 7.74119 5.01403 8.00339 5.01176C8.26558 5.00948 8.51818 5.11027 8.70679 5.29243L12.7068 9.29243C12.8943 9.47996 12.9996 9.73427 12.9996 9.99943C12.9996 10.2646 12.8943 10.5189 12.7068 10.7064L8.70679 14.7064C8.51926 14.8939 8.26495 14.9992 7.99979 14.9992C7.73462 14.9992 7.48031 14.8939 7.29279 14.7064Z"
                        fill="#AEB4B9"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.29279 14.7064C7.10532 14.5189 7 14.2646 7 13.9994C7 13.7343 7.10532 13.48 7.29279 13.2924L10.5858 9.99943L7.29279 6.70643C7.11063 6.51783 7.00983 6.26523 7.01211 6.00303C7.01439 5.74083 7.11956 5.49002 7.30497 5.30461C7.49038 5.1192 7.74119 5.01403 8.00339 5.01176C8.26558 5.00948 8.51818 5.11027 8.70679 5.29243L12.7068 9.29243C12.8943 9.47996 12.9996 9.73427 12.9996 9.99943C12.9996 10.2646 12.8943 10.5189 12.7068 10.7064L8.70679 14.7064C8.51926 14.8939 8.26495 14.9992 7.99979 14.9992C7.73462 14.9992 7.48031 14.8939 7.29279 14.7064Z"
                        fill="#AEB4B9"
                      />
                    </svg>
                  )}
                </div>
              </li>
              <li>
                <h5>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM13.707 8.707C13.8892 8.5184 13.99 8.2658 13.9877 8.0036C13.9854 7.7414 13.8802 7.49059 13.6948 7.30518C13.5094 7.11977 13.2586 7.0146 12.9964 7.01233C12.7342 7.01005 12.4816 7.11084 12.293 7.293L9 10.586L7.707 9.293C7.5184 9.11084 7.2658 9.01005 7.0036 9.01233C6.7414 9.0146 6.49059 9.11977 6.30518 9.30518C6.11977 9.49059 6.0146 9.7414 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.707L13.707 8.707Z"
                      fill="#BABEC3"
                    ></path>
                  </svg>
                  Connect with Reviewbit
                </h5>
                <div className={styles.cta}>
                  {isPremium ? (
                    step == 1 ? (
                      <button
                        title=""
                        onClick={() => setVerifyModal(!openVerifyModal)}
                        disabled={step == 1 ? false : true}
                      >
                        Connect
                      </button>
                    ) : (
                      <button className={styles.disabled}>Connect</button>
                    )
                  ) : (
                    <button className={styles.disabled}>Connect</button>
                  )}
                  {step == 1 ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.29279 14.7064C7.10532 14.5189 7 14.2646 7 13.9994C7 13.7343 7.10532 13.48 7.29279 13.2924L10.5858 9.99943L7.29279 6.70643C7.11063 6.51783 7.00983 6.26523 7.01211 6.00303C7.01439 5.74083 7.11956 5.49002 7.30497 5.30461C7.49038 5.1192 7.74119 5.01403 8.00339 5.01176C8.26558 5.00948 8.51818 5.11027 8.70679 5.29243L12.7068 9.29243C12.8943 9.47996 12.9996 9.73427 12.9996 9.99943C12.9996 10.2646 12.8943 10.5189 12.7068 10.7064L8.70679 14.7064C8.51926 14.8939 8.26495 14.9992 7.99979 14.9992C7.73462 14.9992 7.48031 14.8939 7.29279 14.7064Z"
                        fill="#AEB4B9"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.29279 14.7064C7.10532 14.5189 7 14.2646 7 13.9994C7 13.7343 7.10532 13.48 7.29279 13.2924L10.5858 9.99943L7.29279 6.70643C7.11063 6.51783 7.00983 6.26523 7.01211 6.00303C7.01439 5.74083 7.11956 5.49002 7.30497 5.30461C7.49038 5.1192 7.74119 5.01403 8.00339 5.01176C8.26558 5.00948 8.51818 5.11027 8.70679 5.29243L12.7068 9.29243C12.8943 9.47996 12.9996 9.73427 12.9996 9.99943C12.9996 10.2646 12.8943 10.5189 12.7068 10.7064L8.70679 14.7064C8.51926 14.8939 8.26495 14.9992 7.99979 14.9992C7.73462 14.9992 7.48031 14.8939 7.29279 14.7064Z"
                        fill="#AEB4B9"
                      />
                    </svg>
                  )}
                </div>
              </li>
            </ul>
          </div>
        );
      case "pending":
        return (
          <div className={styles.pending}>
            <h4>
              Message templates are under review. This might take few moments
            </h4>
          </div>
        );
      case "success":
        return (
          <div className={styles.success}>
            <h3>Connected to</h3>
            <h4>{channel?.phoneNumber}</h4>
            <h5>Modify Settings</h5>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div className={styles.verifyCard}>
      <div className={styles.head}>
        <h2>Custom Whatsapp Integration</h2>
        <button>
          {status == "not-started"
            ? "Not Connected"
            : step == "pending"
            ? "Pending"
            : step == "success"
            ? "Connected"
            : "Pending"}
        </button>
      </div>
      <hr />

      {card(status)}

      <hr />
      <div className={styles.bottom}>
        <h6>Powered by 360dialog</h6>
        {isPremium && status != "Connected" ? <h3 onClick={()=> setManualModal(true)}>Integrate manually</h3> : null}
      </div>
      {status == "not-started" && (
        <VerifyModal open={openVerifyModal} setOpen={setVerifyModal} />
      )}
      {isPremium && (

      <ManualModal open={openManualModal} setOpen={setManualModal} />
      )}
    </div>
  );
}

export default Three60;
