import React, { useEffect, useState } from "react";
import styles from "./TotalRating.module.scss";
import { ProgressBar, Spinner } from "@shopify/polaris";
import Link from "next/link";

function TotalRating({ data, loader }) {
  const [ratingData, setRatingData] = useState(null);
  const [total_count, setTotalCount] = useState(0);
  const [multi_total_count, setMulti_TotalCount] = useState(0);

  useEffect(() => {
    if (!data) return;
    setRatingData(data);
    setTotalCount(
      data.star5 + data.star4 + data.star3 + data.star2 + data.star1
    );
    setMulti_TotalCount(
      5 * data.star5 +
        4 * data.star4 +
        3 * data.star3 +
        2 * data.star2 +
        1 * data.star1
    );
  }, [data]);

  const getPercentage = (starValue) => {
    var perc = parseInt((starValue / total_count) * 100);
    var result = 0;
    if (isNaN(perc)) {
      result = 0;
    } else {
      result = perc;
    }
    return result;
  };

  return (
    <div className={styles.totalRating}>
      <div className={styles.head}>
        <h2>Average rating across the products</h2>
        {!loader && (
          <h3>
            {(multi_total_count / total_count).toFixed(1) == "NaN"
              ? 0
              : (multi_total_count / total_count).toFixed(1)}
            <small>/ 5</small>
          </h3>
        )}
      </div>
      {loader == true || !ratingData ? (
        <div className={styles.cardLoader}>
          <Spinner size="large" />
        </div>
      ) : (
        <>
          <hr />
          <div className={styles.stars}>
            <div className={styles.starItem}>
              <div className={styles.star}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.04897 2.92696C9.34897 2.00596 10.652 2.00596 10.951 2.92696L12.021 6.21896C12.0863 6.41944 12.2134 6.59414 12.384 6.71806C12.5547 6.84199 12.7601 6.9088 12.971 6.90896H16.433C17.402 6.90896 17.804 8.14896 17.021 8.71896L14.221 10.753C14.05 10.877 13.9227 11.052 13.8573 11.2528C13.7919 11.4537 13.7918 11.67 13.857 11.871L14.927 15.163C15.227 16.084 14.172 16.851 13.387 16.281L10.587 14.247C10.4162 14.123 10.2105 14.0562 9.99947 14.0562C9.78842 14.0562 9.58277 14.123 9.41197 14.247L6.61197 16.281C5.82797 16.851 4.77397 16.084 5.07297 15.163L6.14297 11.871C6.20815 11.67 6.20803 11.4537 6.14264 11.2528C6.07725 11.052 5.94994 10.877 5.77897 10.753L2.97997 8.71996C2.19697 8.14996 2.59997 6.90996 3.56797 6.90996H7.02897C7.24002 6.91001 7.44568 6.84329 7.6165 6.71935C7.78732 6.59541 7.91455 6.42061 7.97997 6.21996L9.04997 2.92796L9.04897 2.92696Z"
                    fill="#B98900"
                  />
                </svg>
                5
              </div>
              <div className={styles.progress}>
                <ProgressBar
                  progress={parseInt((ratingData.star5 / total_count) * 100)}
                  size="small"
                  color="primary"
                />
              </div>
              <div className={styles.value}>
                {getPercentage(ratingData.star5)}%
              </div>
            </div>
            <div className={styles.starItem}>
              <div className={styles.star}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.04897 2.92696C9.34897 2.00596 10.652 2.00596 10.951 2.92696L12.021 6.21896C12.0863 6.41944 12.2134 6.59414 12.384 6.71806C12.5547 6.84199 12.7601 6.9088 12.971 6.90896H16.433C17.402 6.90896 17.804 8.14896 17.021 8.71896L14.221 10.753C14.05 10.877 13.9227 11.052 13.8573 11.2528C13.7919 11.4537 13.7918 11.67 13.857 11.871L14.927 15.163C15.227 16.084 14.172 16.851 13.387 16.281L10.587 14.247C10.4162 14.123 10.2105 14.0562 9.99947 14.0562C9.78842 14.0562 9.58277 14.123 9.41197 14.247L6.61197 16.281C5.82797 16.851 4.77397 16.084 5.07297 15.163L6.14297 11.871C6.20815 11.67 6.20803 11.4537 6.14264 11.2528C6.07725 11.052 5.94994 10.877 5.77897 10.753L2.97997 8.71996C2.19697 8.14996 2.59997 6.90996 3.56797 6.90996H7.02897C7.24002 6.91001 7.44568 6.84329 7.6165 6.71935C7.78732 6.59541 7.91455 6.42061 7.97997 6.21996L9.04997 2.92796L9.04897 2.92696Z"
                    fill="#B98900"
                  />
                </svg>
                4
              </div>
              <div className={styles.progress}>
                <ProgressBar
                  progress={parseInt((ratingData.star4 / total_count) * 100)}
                  size="small"
                  color="primary"
                />
              </div>
              <div className={styles.value}>
                {getPercentage(ratingData.star4)}%
              </div>
            </div>
            <div className={styles.starItem}>
              <div className={styles.star}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.04897 2.92696C9.34897 2.00596 10.652 2.00596 10.951 2.92696L12.021 6.21896C12.0863 6.41944 12.2134 6.59414 12.384 6.71806C12.5547 6.84199 12.7601 6.9088 12.971 6.90896H16.433C17.402 6.90896 17.804 8.14896 17.021 8.71896L14.221 10.753C14.05 10.877 13.9227 11.052 13.8573 11.2528C13.7919 11.4537 13.7918 11.67 13.857 11.871L14.927 15.163C15.227 16.084 14.172 16.851 13.387 16.281L10.587 14.247C10.4162 14.123 10.2105 14.0562 9.99947 14.0562C9.78842 14.0562 9.58277 14.123 9.41197 14.247L6.61197 16.281C5.82797 16.851 4.77397 16.084 5.07297 15.163L6.14297 11.871C6.20815 11.67 6.20803 11.4537 6.14264 11.2528C6.07725 11.052 5.94994 10.877 5.77897 10.753L2.97997 8.71996C2.19697 8.14996 2.59997 6.90996 3.56797 6.90996H7.02897C7.24002 6.91001 7.44568 6.84329 7.6165 6.71935C7.78732 6.59541 7.91455 6.42061 7.97997 6.21996L9.04997 2.92796L9.04897 2.92696Z"
                    fill="#B98900"
                  />
                </svg>
                3
              </div>
              <div className={styles.progress}>
                <ProgressBar
                  progress={parseInt((ratingData.star3 / total_count) * 100)}
                  size="small"
                  color="primary"
                />
              </div>
              <div className={styles.value}>
                {getPercentage(ratingData.star3)}%
              </div>
            </div>
            <div className={styles.starItem}>
              <div className={styles.star}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.04897 2.92696C9.34897 2.00596 10.652 2.00596 10.951 2.92696L12.021 6.21896C12.0863 6.41944 12.2134 6.59414 12.384 6.71806C12.5547 6.84199 12.7601 6.9088 12.971 6.90896H16.433C17.402 6.90896 17.804 8.14896 17.021 8.71896L14.221 10.753C14.05 10.877 13.9227 11.052 13.8573 11.2528C13.7919 11.4537 13.7918 11.67 13.857 11.871L14.927 15.163C15.227 16.084 14.172 16.851 13.387 16.281L10.587 14.247C10.4162 14.123 10.2105 14.0562 9.99947 14.0562C9.78842 14.0562 9.58277 14.123 9.41197 14.247L6.61197 16.281C5.82797 16.851 4.77397 16.084 5.07297 15.163L6.14297 11.871C6.20815 11.67 6.20803 11.4537 6.14264 11.2528C6.07725 11.052 5.94994 10.877 5.77897 10.753L2.97997 8.71996C2.19697 8.14996 2.59997 6.90996 3.56797 6.90996H7.02897C7.24002 6.91001 7.44568 6.84329 7.6165 6.71935C7.78732 6.59541 7.91455 6.42061 7.97997 6.21996L9.04997 2.92796L9.04897 2.92696Z"
                    fill="#B98900"
                  />
                </svg>
                2
              </div>
              <div className={styles.progress}>
                <ProgressBar
                  progress={parseInt((ratingData.star2 / total_count) * 100)}
                  size="small"
                  color="primary"
                />
              </div>
              <div className={styles.value}>
                {getPercentage(ratingData.star2)}%
              </div>
            </div>
            <div className={styles.starItem}>
              <div className={styles.star}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.04897 2.92696C9.34897 2.00596 10.652 2.00596 10.951 2.92696L12.021 6.21896C12.0863 6.41944 12.2134 6.59414 12.384 6.71806C12.5547 6.84199 12.7601 6.9088 12.971 6.90896H16.433C17.402 6.90896 17.804 8.14896 17.021 8.71896L14.221 10.753C14.05 10.877 13.9227 11.052 13.8573 11.2528C13.7919 11.4537 13.7918 11.67 13.857 11.871L14.927 15.163C15.227 16.084 14.172 16.851 13.387 16.281L10.587 14.247C10.4162 14.123 10.2105 14.0562 9.99947 14.0562C9.78842 14.0562 9.58277 14.123 9.41197 14.247L6.61197 16.281C5.82797 16.851 4.77397 16.084 5.07297 15.163L6.14297 11.871C6.20815 11.67 6.20803 11.4537 6.14264 11.2528C6.07725 11.052 5.94994 10.877 5.77897 10.753L2.97997 8.71996C2.19697 8.14996 2.59997 6.90996 3.56797 6.90996H7.02897C7.24002 6.91001 7.44568 6.84329 7.6165 6.71935C7.78732 6.59541 7.91455 6.42061 7.97997 6.21996L9.04997 2.92796L9.04897 2.92696Z"
                    fill="#B98900"
                  />
                </svg>
                1
              </div>
              <div className={styles.progress}>
                <ProgressBar
                  progress={parseInt((ratingData.star1 / total_count) * 100)}
                  size="small"
                  color="primary"
                />
              </div>
              <div className={styles.value}>
                {getPercentage(ratingData.star1)}%
              </div>
            </div>
          </div>
          <hr />
          <Link href={"/reviews"}>
            <h5>See all reviews</h5>
          </Link>
        </>
      )}
    </div>
  );
}

export default TotalRating;
