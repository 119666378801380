import React, { useCallback, useEffect, useState, useRef } from "react";
import styles from "./manual.module.scss";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import { Spinner } from "@shopify/polaris";
import main_apis from "api/main";
import toast from "react-hot-toast";
import { useRouter } from "next/router";

const ManualModal = ({ open, setOpen }) => {
  const cookies = new Cookies();
  
  const [phoneNumber, setPhoneNumber] = useState();
  const [appId, setAppId] = useState();
  const [whatsappId, setWhatsappId] = useState();

  const [apiKey, setApiKey] = useState();

  const [channelID, setChannelId] = useState();

  const [loading, setLoading] = useState(false);
const router = useRouter();
  

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      minWidth: "600px",
      transform: "translate(-50%, -50%)",
      maxWidth: "1000px",
      background: "rgba(0,0,0,0)",
      border: "0",
      overflow: "visible",
      zIndex: "800",
    },
  };

  
const handleSubmit = (e)=> {
    e.preventDefault();
       
        main_apis.manage_api_key(cookies.get("shopId"),
        cookies.get("merchantToken"),apiKey,channelID,appId,phoneNumber,whatsappId).then((res)=> {
          if(res.status == 200){
    
            setLoading(false);
            setOpen(false);
            router.reload();
    
          }else if(res.status == 400){
            toast.error("Please fill the details")
          }
    
        })
       
}
 
 

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      contentLabel=""
      style={customStyles}
    >
      <div className={styles.manualModal}>
        <div className={styles.leftMain}>
          <h3>Integrate manually</h3>
          <hr />
          <p>You can find all credentials from your 360 dashboard.</p>
          <form onSubmit={handleSubmit}>
          <div className={styles.inputItem}>
              <label htmlFor="">Api key</label>
              <input
                type="text"
                value={apiKey}
                required
                onChange={(e)=> setApiKey(e.target.value)}

              />
            </div>
            <div className={styles.inputItem}>
              <label htmlFor="">Channel Id</label>
              <input
                type="text"
                value={channelID}
                required
                onChange={(e)=> setChannelId(e.target.value)}
              />
            </div>
            <div className={styles.inputItem}>
              <label htmlFor="">Whatsapp No</label>
              <input
                type="number"
                value={phoneNumber}
                required
                onChange={(e)=> setPhoneNumber(e.target.value)}
              />
            </div> <div className={styles.inputItem}>
              <label htmlFor="">App Id</label>
              <input
                type="text"
                value={appId}
                required
                onChange={(e)=> setAppId(e.target.value)}
              />
            </div> <div className={styles.inputItem}>
              <label htmlFor="">Whatsapp Account Id</label>
              <input
                type="text"
                value={whatsappId}
                required
                onChange={(e)=> setWhatsappId(e.target.value)}
              />
            </div>

              <>
                <button
                  onClick={() => {
                    setOpen(false);
                  }}
                  className={styles.cancel}
                >
                  No, Cancel
                </button>
                {loading ?  <button className={styles.btnLoader}><Spinner  accessibilityLabel="Small spinner example" size="small" /></button> : (

                <button
                  className={styles.agree}
                  type="submit"
                >
                  Submit
                </button>)}
              </>
          </form>
        
        

        </div>
      </div>
    </Modal>
  );
};

export default ManualModal;
