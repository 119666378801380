import { useState } from "react";
import {
  Modal,
  TextStyle,
  Layout,
  Stack,
  DisplayText,
  Icon,
  FormLayout,
  Select,
  ChoiceList,
  TextField,
  Button,
} from "@shopify/polaris";
import Styles from "./pastcustomer.module.scss";
import cn from "classnames";
import { TickSmallMinor } from "@shopify/polaris-icons";
// import toastr from '../../lib/toastr'
import toastr from "react-hot-toast";
import Cookies from "universal-cookie";
import api, { onboardingflowstage } from "../../api";

let options = [];
for (let i = 1; i < 22; i++) {
  let value = i;
  if (i >= 15) value = 30 + (i - 14) * 10;
  else if (i > 10) value = 10 + (i - 10) * 5;
  options.push({ label: `${value}`, value: `${value}` });
}

const PastCustomer = ({
  timeGap = 7,
  messageEvent = "fulfillment",
  reviewStatus = reviewStatus,
  // timegap=timegap,
  // change=change,
  // onBoardingStep=onBoardingStep,
  // importorders=importorders,
}) => {
  const [enabled, setEnabled] = useState((reviewStatus = reviewStatus));
  const [showPopup, setShowPopup] = useState(true);
  const [step, setStep] = useState(1);
  const [timeGapVal, setTimeGapVal] = useState(timeGap);
  const [messageEventVal, setMessageEventVal] = useState(messageEvent);
  const [days, setDays] = useState("90");
  const [review, setReview] = useState("");
  const cookies = new Cookies();
  const [mount, setMount] = useState(false);

  // if(reviewStatus==true&&mount==false){
  //     if(timegap==10){
  //         setStep(2)
  //     }
  //     else
  //     {
  //         setStep(3)
  //     }

  //     setMount(true)
  //     console.log("reviewStatus not set")
  // }

  const handleReviewStatus = (value) => {
    // setReviewStatus(value)
    let flag = value;
    value = value == true ? false : true;

    //console.log(value)
    api.updateReviewStatus(value).then((returndata) => {
      //console.log(returndata.data);

      if (
        returndata.data.confirmation_url &&
        returndata.data.confirmation_url != ""
      ) {
        window.location.href = returndata.data.confirmation_url;
      }
      toastr.success("Updated");
      if (flag == true) {
        setEnabled(false);
      }
    });
  };
  //Message Timegap change action
  const handleTimeGapChange = (value) => {
    api.updateTimeGap(value).then((returndata) => {
      //console.log(returndata.data);
      toastr.success(returndata.data.Message);
    });
    setTimeGapVal(value);
  };
  //Message Schedule Event Change Action
  const handleMessageEventChange = (value) => {
    api.updateMessageEvent(value).then((returndata) => {
      //console.log(returndata.data);
      toastr.success(returndata.data.Message);
    });
    setMessageEventVal(value);
  };

  const handleNext = () => {
    if (step < 4) {
      setStep(step + 1);
      change(step);
    } else {
      //console.log("Save Data")
      toggleShowPopup();
    }
    if (step == 4) {
      change(step + 1);
    }
  };
  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const toggleShowPopup = () => {
    setShowPopup(!showPopup);
  };

  const handleSave = () => {
    alert("Save");
  };

  //Message Timegap change action

  return (
    <Modal
      sectioned={false}
      open={showPopup}
      onClose={toggleShowPopup}
      title=""
      hiddenTitle
    >
      <div
        style={{
          padding: "2rem",
          minHeight: "65vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <DisplayText>
            <TextStyle variation="strong">Let's get started</TextStyle>
          </DisplayText>
          <TextStyle variation="strong">
            Request review from past customers
          </TextStyle>
          <div className={cn("spacer", Styles.onboardStepsHead)}>
            {/* <Stack alignment="center">
                            {
                                [1, 2, 3, 4].map((item) => {

                                    let classes = cn(Styles.step)
                                    if (step > item)
                                        classes = cn(classes, Styles.completed)
                                    else if (step === item)
                                        classes = cn(classes, Styles.active)
                                    return (
                                        <span className={classes}>
                                            {item >= step ?
                                                item
                                                : <Icon source={TickSmallMinor} />
                                            }
                                        </span>
                                    )
                                })
                            }
                            {/* <span className={cn(Styles.step, Styles.active)}>2</span>
                        <span className={Styles.step}>3</span>
                        <span className={Styles.step}>4</span> */}
            {/* </Stack> */}
            {/* <div className={Styles.stepCount}>Step 2</div> */}
          </div>
        </div>

        <div style={{ flex: 1 }}>
          {/* Step 3 */}

          <div className={cn(Styles.stepBody, step == 1 ? Styles.show : "")}>
            <Stack vertical>
              <TextStyle variation="subdued">
                Email will be sent in 24 hours, so you have enough time to
                customize the email and offer a discount, if you wish.
              </TextStyle>
              <FormLayout>
                <ChoiceList
                  choices={[
                    { label: "90 days back (Recommended)", value: "90" },
                    { label: "60 days back", value: "60" },
                    { label: "30 days back", value: "30" },
                  ]}
                  selected={days}
                  onChange={(value) => {
                    setDays(value), value[0] !== 0 && importorders(value[0]);
                  }}
                />
              </FormLayout>
            </Stack>
          </div>
        </div>

        <div className={Styles.footer}>
          <Stack distribution="fill" alignment="center">
            {/* <TextStyle variation="strong">{step - 1}/4 step completed</TextStyle> */}
            <Stack distribution="trailing" alignment="center">
              {/* <button className={Styles.normalButton}
                            onClick={handleDoItLater}>Do It Later</button>
                            <button className={Styles.actionButton}
                                onClick={handleBack}
                            >
                                Back
                            </button> */}
              <button className={Styles.actionButton} onClick={toggleShowPopup}>
                Submit
              </button>
            </Stack>
          </Stack>
        </div>
      </div>
    </Modal>
  );
};
export default PastCustomer;
