import React, { useState, useContext, useEffect } from "react";
import styles from "./OnBoarding.module.scss";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import StepFour from "./Steps/StepFour";
import StepFive from "./Steps/StepFive";
import main_apis from "api/main";
import EnableModal from "./EnableModal/EnableModal";
import toast, { Toaster } from "react-hot-toast";
import toastr from "toastr";
import Cookies from "universal-cookie";
import { GlobalContext } from "context/GlobalContext";

const OnBoarding = ({ setTour, setOnboarding, setInitSetupStore }) => {
  const { isauth, merchant_token, shop_token } = useContext(GlobalContext);
  const [steps, setSteps] = useState(2);
  const [product, setProduct] = useState();
  const [reply, setReply] = useState();

  const cookies = new Cookies();

  const Step = () => {
    switch (steps) {
      case 1:
        return (
          <StepOne
            setTour={setTour}
            setSteps={setSteps}
            setOnboarding={setOnboarding}
            setInitSetupStore={setInitSetupStore}
            manageTour={manageTour}
          />
        );
      case 2:
        return (
          <StepTwo
            setTour={setTour}
            setSteps={setSteps}
            setOnboarding={setOnboarding}
            sendSampleReview={sendSampleReview}
            setInitSetupStore={setInitSetupStore}
            manageTour={manageTour}
          />
        );
      case 3:
        return <StepThree product={product} setSteps={setSteps} />;
      case 4:
        return (
          <StepFour
            getReviewReply={getReviewReply}
            product={product}
            setSteps={setSteps}
            setTour={setTour}
          />
        );
      case 5:
        return (
          <StepFive
            reply={reply}
            setReply={setReply}
            product={product}
            setSteps={setSteps}
            setTour={setTour}
            setOnboarding={setOnboarding}
            setInitSetupStore={setInitSetupStore}
            getReviewReply={getReviewReply}
          />
        );

      default:
        break;
    }
  };
  console.log(merchant_token, shop_token, "onboarding");

  const sendSampleReview = ({ setLoading, phoneNumber }) => {
    main_apis
      .send_sample_review({
        phoneNumber: phoneNumber,
        merchantToken: cookies.get("merchantToken"),
        shopId: cookies.get("shopId"),
      })
      .then((res) => {
        if (res.data.Status == 200) {
          setProduct(res.data.data);
          localStorage.setItem("message", JSON.stringify(res.data.data));
          localStorage.setItem(
            "reviewId",
            JSON.stringify(res.data.data.reviewRequestId)
          );
          localStorage.setItem(
            "reviewPhone",
            JSON.stringify(res.data.data.phoneNumber)
          );

          setSteps(3);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  const getReviewReply = (data) => {
    setReply(data);
    if (data && data.rating !== 0) {
      localStorage.setItem("tour", JSON.stringify(data));
      setTimeout(() => {
        setSteps(5);
      }, 2000);
    }
  };

  const manageTour = (status) => {
    main_apis
      .manage_tour(status, cookies.get("merchantToken"), cookies.get("shopId"))
      .then((res) => {
        localStorage.removeItem("tour");
        localStorage.removeItem("message");
        localStorage.removeItem("reviewId");
      })
      .catch(() => {});
  };

  return (
    <div className={styles.onBoarding}>
      <div className={styles.container}>
        <div className={styles.parent}>
          <Step setTour={setTour} setOnboarding={setOnboarding} />
        </div>
      </div>
    </div>
  );
};

export default OnBoarding;
