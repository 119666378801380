import React, { useEffect } from "react";
import styles from "../OnBoarding.module.scss";
import StepComponent from "./StepComponent";
import { useForm } from "react-hook-form";
import { StepBottom } from "@/components/OnBoarding/Steps/StepOne";
import { useState } from "react";
import countries from "../../../countries.json";
import main_apis from "api/main";
import Cookies from "universal-cookie";
import axios from "axios";

const StepTwo = ({
  setSteps,
  sendSampleReview,
  setTour,
  setOnboarding,
  setInitSetupStore,
  manageTour,
}) => {
  const [loading, setLoading] = useState(false);

  const cookies = new Cookies();
  const merchantToken = cookies.get("merchantToken");
  const shopId = cookies.get("shopId");

  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    trigger,
    watch,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const handleSubmitForm = (data) => {
    if (isValid) {
      setLoading(true);
      sendSampleReview({
        setLoading,
        phoneNumber: getValues("countryCode") + getValues("mobileNumber"),
      });
    }
  };

  useEffect(() => {
    let phone = JSON.stringify(localStorage.getItem("reviewPhone"));
    let defCode = countries.filter((f) => f.name == cookies.get("countryName"));
    if (defCode && defCode[0] && defCode.phoneCode) {
      setValue("countryCode", defCode && defCode[0].phoneCode);
    }
  }, []);

  const Loader = () => {
    return (
      <div className={styles.loader}>
        <h5>Searching for a product...</h5>
      </div>
    );
  };

  return (
    <StepComponent
      progress={25}
      bottom={
        <StepBottom
          setOnboarding={setOnboarding}
          setTour={setTour}
          setInitSetupStore={setInitSetupStore}
          manageTour={manageTour}
        />
      }
      progressMin={25}
    >
      <div className={styles.stepTwo}>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <h5>Your Whatsapp Number</h5>
            <p>
              See reviewbit in action by sending a test message on your phone
              and see the magic.
            </p>
            <div className={styles.getNumber}>
              <div className={styles.formBox}>
                <div className={styles.countryCode}>
                  <select
                    {...register("countryCode", {
                      required: true,
                    })}
                  >
                    {countries.map((i) => (
                      <option value={i.phoneCode}>
                        {i.phoneCode != getValues("countryCode") && i.name} +
                        {i.phoneCode}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={styles.mobile}>
                  <input
                    {...register("mobileNumber", {
                      required: true,
                      // min: 6,
                      // max: 12,
                    })}
                    type="number"
                  />
                </div>
              </div>
              {errors.mobileNumber?.type == "required" && (
                <span className={styles.error}>This field is required</span>
              )}
              {errors.countryCode?.type == "required" && (
                <span className={styles.error}>This field is required</span>
              )}
              {errors.mobileNumber?.type == "min" && (
                <span className={styles.error}>Invalid number</span>
              )}
              {errors.mobileNumber?.type == "max" && (
                <span className={styles.error}>Invalid number</span>
              )}
            </div>
            <button
              onClick={() => handleSubmitForm()}
              type="submit"
              className={styles.sendDemo}
            >
              Send sample review
            </button>
          </form>
        )}
      </div>
    </StepComponent>
  );
};

export default StepTwo;
